import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';
import { Paragraph } from '../Paragraph/Paragraph';

export const SearchInputStyled = styled.div`
  position: relative;
`;

export const SearchInputForm = styled.form`
  display: flex;
  flex-direction: row;
  border-bottom: thin solid ${colors.black};
`;

const buttonStyles = css`
  appearance: none;
  border: none;
  background: transparent;
  height: ${spacing.block};
  width: ${spacing.regular};
  padding: 0;

  &:hover {
    background: transparent;
    color: ${colors.gray1};
  }

  &:disabled {
    opacity: 0.33;
    background: transparent;

    svg {
      opacity: 1;
      color: ${colors.black};
      margin-left: 0;
    }
  }
`;

export const SearchInputChildren = styled.div`
  margin-bottom: ${spacing.small};
`;

export const ResultText = styled(Paragraph)`
  margin-top: ${spacing.block};
  margin-bottom: 0;
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.body};
  text-align: center;

  &:empty {
    display: none;
  }
`;

export const ClearButton = styled(Button)`
  ${buttonStyles};
`;

export const SearchButton = styled(Button)`
  ${buttonStyles};
  margin-left: ${spacing.xsmall};
`;
