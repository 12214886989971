import styled from 'styled-components';

interface LoaderStyledProps {
  $small?: boolean;
  $green?: boolean;
}
export const LoaderStyled = styled.div<LoaderStyledProps>`
  width: 56px;
  height: ${({ $small }) => ($small ? '56px' : '100vh')};
  margin: 0 auto;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    animation: spin infinite forwards 1s;

    @keyframes spin {
      0% {
        transform: rotate(90deg);
      }

      100% {
        transform: rotate(450deg);
      }
    }
  }
`;
