import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

interface FilterStyledProps {
  $isMobileFilter?: boolean;
}
export const FilterStyled = styled.div<FilterStyledProps>`
  .MobileFilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0 15px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      display: none;
    }
  }

  select {
    width: 100px;
    margin-right: 20px;
  }



  .BoxFilters {
    display: flex;
    justify-content: space-between;
    margin: 0 0 ${spacing.small} 0;
    flex-direction: row;

    .DropDownFiltersDesktop {
      display: flex;

      @media screen and (max-width: ${breakpoints.desktop}) {
        display: ${({ $isMobileFilter }) =>
          !$isMobileFilter ? 'none' : 'flex'};
      }

      flex-direction: row;
    }

    .DropDownFiltersMobile {
      display: flex;
      flex-direction: column;
    }

    .FilterWrapper {
      position: relative;

      button {
        padding: 0;
        margin-right: ${spacing.regular};

        &.Active {
          &::after {
            transform: rotate(180deg);
            top: unset;
          }
        }

        &.ChildSelected {
          span {
            position: relative;
          }
        }
      }

    }
  }

  .Hide {
    display: none;
  }
`;

export const Actions = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }

  button {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    img {
      margin-left: ${spacing.xsmall};
    }
  }

  .IconButton {
    margin-left: ${spacing.small};
  }
`;

export const ClearIcon = styled.span`
  margin-left: ${spacing.xsmall};
`;

export const ButtonFilters = styled.div`
 display: flex;
    overflow-x: auto;
    margin: 0 -${spacing.pagePaddingMobile};
    padding: ${spacing.small} ${spacing.pagePaddingMobile};

    @media screen and (min-width: ${breakpoints.desktop}) {
      overflow-x: hidden;
      margin: 0 0 ${spacing.small} 0;
      padding: 0;
    }

    button {
      margin: 0 ${spacing.small} 0 0;
    }

    label {
      padding: 20px;
      border: thin solid ${colors.border};
    }
`;

export const FilterButton = styled.button<{ $filterActive?: boolean }>`
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
  transition: transform .1s ease;

  ${({ $filterActive }) => $filterActive && css`
    transform: rotate(-180deg);
  `}
`;

export const SubFilters = styled.div<{ $active?: boolean }>`
  display: none;
  top: 36px;
  border: thin solid ${colors.gray4};
  padding: ${spacing.small};
  z-index: 2;
  background-color: ${colors.white};
  min-width: 220px;

  ${({ $active }) =>
    $active && css`
      display: block;
    `}

  @media screen and (min-width: ${breakpoints.desktop}) {
    position: absolute;
  }
`;

export const SubFilter = styled.div<{ checked?: boolean }>`
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: ${spacing.small};
  }

  span {
    font-size: ${fontSizes.small};
    letter-spacing: 0.04em;
  }

  input {
    visibility: hidden;
    width: 0;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      position: relative;
      width: ${spacing.small};
      height: ${spacing.small};
      border: thin solid ${colors.black};
      display: block;
      margin-right: ${spacing.xsmall};
    }

    ${({ checked }) => checked && css`
    &::before {
      background: url('/assets/gfx/check.svg');
      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
    }
    `}

    &:hover {
      color: ${colors.gray2};

      &::before {
        background-color: ${colors.gray4};
      }
    }
  }
`;
