import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const PagerStyled = styled.div`
  padding-top: ${spacing.regular};

  .PagerInner {
    max-width: 250px;
    margin: 0 auto;
  }

  .Pager {
    margin-bottom: ${spacing.small};
    text-align: center;
    font-size: ${fontSizes.small};
    letter-spacing: 0.1rem;
  }

  .Gauge {
    margin: 0 0 ${spacing.block} 0;
    width: 100%;
  }

  button {
    width: 100%;
    display: block;
  }
`;
