import localFont from '@next/font/local';

const fontFranklinGothic = localFont({
  src: [
    {
      path: './FranklinGothic-Book.woff',
      weight: '300',
      style: 'normal',
    },
    {
      path: './FranklinGothic-Medium.woff',
      weight: '400',
      style: 'bold',
    },
  ],
  variable: '--font-franklin-gothic',
});

export const Fonts: React.FC = () => {
  return (
    <style jsx global>
      {`
      html,
      input,
      textarea,
      button,
      select {
        font-family: ${fontFranklinGothic.style.fontFamily};
      }
    `}
    </style>
  );
};
