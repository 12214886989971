import Link from 'next/link';
import styled, { css } from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

const desktopMenuHeight = 500;
const menuHeight = 57;

export const DesktopMenuContentWrapper = styled.div<{ $show?: boolean }>`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${spacing.pagePadding};
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  z-index: ${({ $show }) => ($show ? '1' : '-1')};
  transition: opacity 0.2s ${({ $show }) => ($show ? '0.3s linear' : 'linear')};
`;

export const DesktopMenuContent = styled.div`
  display: flex;
  align-items: flex-end;
  height: calc(${desktopMenuHeight}px - ${menuHeight}px);
  padding: ${spacing.medium} 0;

  .SearchWrapper {
    width: 100%;
    flex: 1;
  }
`;

export const DesktopMenuList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;

  > * {
    width: 25%;
    height: auto;
    display: block;
    padding: 8px 0;
  }

  span {
    a {
      font-size: ${fontSizes.h5};
    }
  }
`;

export const DesktopMenuLink = styled(Link)<{ $active?: boolean }>`
  display: block;
  font-size: ${fontSizes.h5};
  text-decoration: none;
  transition: all 0.2s ease;
  color: ${colors.black};

  &:hover,
  &:active {
    color: ${colors.linkhover};
  }
  ${({ $active }) =>
    $active
    && css`
      color: ${colors.red};

      &:hover,
      &:active {
        color: ${colors.red};
      }
    `}
`;

export const DesktopMenuStyled = styled.div`
  .DesktopMenuWrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: calc(${desktopMenuHeight}px - ${menuHeight}px);
    box-sizing: border-box;
  }
`;

export const DesktopMenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: calc(${desktopMenuHeight}px - ${menuHeight}px);
  box-sizing: border-box;
`;

export const Divider = styled.div`
  border-top: thin solid ${colors.border};
  margin-top: ${spacing.small};
`;
