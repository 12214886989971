import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/hultafors/tokens';

export const FavoriteStyled = styled.button<{
  $isFavorite?: boolean;
  $small?: boolean;
}>`
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;

  ${({ $small }) =>
    !$small
    && css`
      @media screen and (min-width: ${breakpoints.desktop}) {
        width: 24px;
        height: 24px;
      }

      svg {
        @media screen and (min-width: ${breakpoints.desktop}) {
          width: 24px;
          height: 24px;
        }
      }
    `}

  svg {
    width: 16px;
    height: 16px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      width: 24px;
      height: 24px;
    }

    transition: all 0.2s;

    path,
    circle {
      transition: all 0.2s;
    }
  }

  ${({ $isFavorite }) =>
    $isFavorite
      ? css`
          svg path {
            stroke: ${colors.red};
            fill: ${colors.red};
          }

          &:hover svg {
            transform: scale(0.95);

            path {
              stroke: ${colors.gray2};
              fill: ${colors.gray2};
            }
          }
        `
      : css`
          svg path {
            stroke: ${colors.gray2};
            fill: transparent;
          }

          &:hover svg {
            transform: scale(1.05);

            path {
              stroke: ${colors.red};
              fill: ${colors.red};
            }
          }
        `}
`;
