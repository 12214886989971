import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

const arrowWidth = 24;
const controllerWidth = 100;

interface ImageSliderStyledProps {
  $article?: boolean;
  $environment?: boolean;
}

export const ImageSliderStyled = styled.div<ImageSliderStyledProps>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;

  ${({ $article }) =>
    $article
    && css`
      padding-bottom: ${spacing.block};
    `}

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .ImgWrapper {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;

    .ImageLoader {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.VideoImage {
      .VideoWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${colors.white};
      }

      .PlayButton {
        position: absolute;
        display: block;
        width: ${spacing.xlarge};
        height: ${spacing.xlarge};
        padding: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: none;
        border: none;
        color: ${colors.white};
      }
    }

    &.inModal {
      width: 100%;
      height: 100%;
      display: flex;
      background: ${colors.gray4};

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: auto;
      }
    }

    &.inModal:not(.VideoImage) {
      padding: ${spacing.large};
    }

    img.ProductImage {
      width: 100%;
      height: 80%;
      object-fit: contain;
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
    }
  }

  .Counter {
    display: inline-block;
    position: absolute;
    left: 40px;
    transform: translateX(-50%);
    bottom: -30px;
    color: ${colors.black};
    font-size: 14px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      bottom: -30px;
      left: 40px;
      transform: none;
    }

    .ActiveSlide {
      font-size: 14px;
      color: ${colors.black};
    }

    &.ProductPage {
      bottom: ${spacing.small};
      left: 50%;
      transform: translateX(-50%);
      color: ${colors.black};
    }

    &.Article {
      left: calc(50% - 50px);
      bottom: -${spacing.small};
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${spacing.regular};
      width: ${controllerWidth}px;

      @media screen and (max-width: ${breakpoints.desktop}) {
        left: 50%;
      }
    }

    &.inModal {
      height: ${spacing.regular};
      line-height: ${spacing.regular};
      bottom: ${spacing.regular};
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .Text {
    display: flex;
    flex-direction: row;
    height: auto;
    font-size: ${fontSizes.small};
    line-height: 1.375rem;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      margin-top: ${spacing.small};
    }

    &.hidden {
      opacity: 0;
    }

    p {
      margin: ${spacing.small} 0;

      @media screen and (min-width: ${breakpoints.largeMobile}) {
        margin: 0;
        width: 60%;
      }
    }
  }

  .slick-slider {
    position: relative;
    display: block;
    height: 100%;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
  }

  .slick-prev,
  .slick-next {
    border: none;
    outline: 0;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }
  }

  .slick-slider:not(.inModal, .article) {
    .slick-prev,
    .slick-next {
      width: 36px;
      height: 36px;
      position: absolute;
      z-index: 1;
      bottom: 50%;
      background: url('/assets/gfx/arrows/right.svg') center center no-repeat;
      background-position: center;

      @media screen and (min-width: ${breakpoints.desktop}) {
        opacity: 0.4;
      }
    }

    .slick-prev {
      left: 0;
      background-size: 16px 16px;
      transform: rotate(180deg) translateY(-50%);
    }

    .slick-next {
      right: 0;
      background-size: 16px 16px;
      transform: translateY(50%);
    }
  }

  .slick-slider.inModal,
  .slick-slider.article {
    .slick-prev,
    .slick-next {
      width: ${spacing.regular};
      height: ${spacing.regular};
      position: absolute;
      z-index: 1;
      bottom: ${spacing.regular};
      background: url('/assets/gfx/arrows/arrow_puff_left_mobile.svg') center
        center no-repeat;
      background-position: center;
    }

    .slick-prev {
      background-size: ${arrowWidth}px ${arrowWidth}px;
      left: calc(50% - 50px);
      transform: translateX(-50%);

      ${({ $article }) =>
        $article
        && css`
          bottom: -${spacing.medium};
        `}
    }

    .slick-next {
      background-size: ${arrowWidth}px ${arrowWidth}px;
      left: calc(50% + 50px);
      transform: rotate(180deg) translateX(50%);

      ${({ $article }) =>
        $article
        && css`
          bottom: -${spacing.medium};
        `}
    }

    .ImgWrapper {
      background-color: #fff;

      img {
        max-width: 1024px;

        ${({ $article, $environment }) =>
          $article
          && css`
            width: 100%;
            max-width: 100%;
            max-height: ${$environment ? 'inherit' : '800px'};
            object-fit: cover;
          `}
      }
    }
  }

  .slick-list {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;

    ${({ $article }) =>
      !$article
      && css`
        > div {
          height: 100%;
        }
      `}
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: 100%;
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    display: table;
    content: '';
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    > div {
      height: 100%;
    }
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: thin solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;
