import { useCart, useFavorites } from '@hultafors/hultafors/hooks';

import { Badge } from '../Badge/Badge';
import { Icon } from '../Icon/Icon';

import {
  CartIcon,
  FavoriteIcon,
  LanguageIcon,
  NavItemStyled,
  SearchIcon,
} from './NavItem.styled';

interface NavItemProps {
  isFavorite?: boolean;
  isCart?: boolean;
  isLanguage?: boolean;
  isSearch?: boolean;
  toggle?: () => void;
  inTopMenu?: boolean;
  label: string;
  itemsCount?: number;
  className?: string;
  link?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  toggle,
  isFavorite,
  isLanguage,
  isCart,
  isSearch,
  label = '',
  className,
}) => {
  const { cart } = useCart();
  const { favorites } = useFavorites();
  return (
    <NavItemStyled
      onClick={toggle}
      aria-label={label || ''}
      className={className}
    >
      {isFavorite && (
        <FavoriteIcon aria-hidden="true">
          <Icon svg="favorite" />
          {favorites.length > 0 && (
            <Badge tiny round>
              {favorites.length}
            </Badge>
          )}
        </FavoriteIcon>
      )}
      {isCart && (
        <CartIcon aria-hidden="true">
          <Icon svg="cart" />
          {cart.length > 0 && (
            <Badge tiny round red>
              {cart.length}
            </Badge>
          )}
        </CartIcon>
      )}

      {isLanguage && (
        <LanguageIcon>
          <Icon svg="language" />
        </LanguageIcon>
      )}

      {isSearch && (
        <SearchIcon>
          <Icon svg="search" />
        </SearchIcon>
      )}
    </NavItemStyled>
  );
};
