import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

export const StyledNotFoundPageContent = styled.div`
  margin-bottom: ${spacing.xlarge};

  .ProductWrapper {
    padding: 0 ${spacing.small};
    padding-top: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: ${spacing.medium};
    }
  }
`;
