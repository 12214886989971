import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

import { DialogComponent } from '../DialogComponent/DialogComponent';
import { IFrameContainer } from '../IFrameContainer/IFrameContainer';

export const BackDrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: black;
  opacity: 0.3;
  z-index: 2;
`;

export const StyledDialog = styled(DialogComponent)`
  height: 80%;
  width: 90%;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    height: 65%;
    width: 80%;
    max-height: 600px;
    max-width: 1250px;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  inset: 0;
  margin: auto;
  padding: 0;
  height: 80%;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0 ${spacing.medium};
  }
`;

export const ImageWrapper = styled.div`
  max-width: 400px;
  margin-right: ${spacing.medium};

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const StyledIFrameContainer = styled(IFrameContainer)`
  min-width: 300px;
  overflow: hidden;
`;
