import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

interface QuoteBlockStyledProps {
  $fullWidth?: boolean;
}
export const QuoteBlockStyled = styled.div<QuoteBlockStyledProps>`
  margin: ${spacing.medium} 0;
  padding: 0 ${({ $fullWidth }) => !$fullWidth && spacing.pagePaddingMobile};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${spacing.xlarge} 0;
    padding: 0 ${({ $fullWidth }) => !$fullWidth && spacing.pagePadding};
  }

  .QuoteWrapper {
    .QuoteDash {
      display: block;
      height: 1px;
      width: 100%;
      min-width: 28px;
      background-color: ${colors.red};
      margin-top: ${spacing.small};

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin-top: ${spacing.regular};
      }
    }

    .Quote {
      font-size: ${fontSizes.h4};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.h2};
      }
    }

    .Quoter {
      margin: ${spacing.small} 0 0 0;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        margin: ${spacing.regular} 0 0 0;
      }

      span {
        font-size: ${fontSizes.xsmall};
        line-height: ${lineHeights.h4};

        @media screen and (min-width: ${breakpoints.mobileMax}) {
          font-size: ${fontSizes.small};
        }
      }

      .Name {
        font-weight: ${fontWeights.medium};
      }

      .Title {
        position: relative;
        margin-left: ${spacing.regular};
        color: ${colors.gray2};

        &::before {
          content: '';
          position: absolute;
          top: 8px;
          left: -${spacing.small};
          height: 1px;
          width: 10px;
          display: block;
          background-color: ${colors.gray2};
          z-index: -1;
        }
      }
    }
  }
`;
