import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

interface ActiveProps {
  $active?: boolean;
}

interface SliderBlockStyledProps {
  $src?: string;
  $dark?: boolean;
}
export const SliderBlockStyled = styled.div`
  position: relative;

  .swiper {
    width: 100%;
    overflow-x: hidden;
  }

  .swiper-wrapper {
    position: relative;
    display: flex;
  }

  .swiper-slide {
    min-width: 100%;
  }

  .swiper-slide-active {
    z-index: 2;
  }
`;

export const SwiperSlide = styled.div<{ $isActive?: boolean }>``;

const NavigatorBtnShared = css`
  position: absolute;
  width: 100%;
  z-index: 2;
  display: flex;
`;

export const ArrowNavigator = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    ${NavigatorBtnShared}
    top: 50%;
    justify-content: space-between;
    transform: translateY(-50%);
  }
`;

export const DotNavigator = styled.div`
  ${NavigatorBtnShared}
  z-index: 3;
  bottom: 10px;
  justify-content: center;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    bottom: 25px;
    margin-bottom: ${spacing.xlarge};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    bottom: 56px;
    margin-bottom: ${spacing.xlarge};
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    bottom: 100px;
    margin-bottom: ${spacing.xlarge};
  }

  @media screen and (min-width: ${breakpoints.desktopLarger}) {
    bottom: 15%;
    margin-bottom: ${spacing.xlarge};
  }
`;

const ButtonShared = css<SliderBlockStyledProps>`
  cursor: pointer;
  background: none;
  border: none;
  color: ${colors.gray3};

  &:hover {
    color: ${colors.white};
  }

  svg {
    filter: drop-shadow(0 3px 2px rgb(0 0 0 / 70%));
  }
`;
export const ArrowBtn = styled.button<SliderBlockStyledProps>`
  ${ButtonShared}

  svg {
    transform: rotate(-90deg);

    &:active {
      transform: scale(0.96) rotate(-90deg);
    }
  }

  &:first-child {
    svg {
      transform: rotate(90deg);

      &:active {
        transform: scale(0.96) rotate(90deg);
      }
    }
  }
`;

export const DotBtn = styled.button<SliderBlockStyledProps>`
  ${ButtonShared}
  padding: ${spacing.xxsmall};
  margin: ${spacing.xxsmall};
`;

export const DotBtnInner = styled.span<ActiveProps>`
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: ${({ $active }) => ($active ? colors.white : colors.gray2)};

  &:hover {
    background: ${({ $active }) => ($active ? colors.white : colors.gray4)};
  }
`;
