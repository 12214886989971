import styled from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import { colors, lineHeights, spacing } from '@hultafors/hultafors/tokens';

export const AlertBoxStyled = styled.div`
  display: inline-flex;
  padding: ${spacing.small};
  margin-top: ${spacing.xsmall};
  margin-bottom: ${spacing.xsmall};
  background-color: ${colors.white};
  color: ${colors.black};
  text-align: left;
  box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
`;

export const Label = styled.span`
  color: ${colors.black};
  line-height: ${lineHeights.small};
`;

export const ErrorIcon = styled.div`
  margin-right: ${spacing.xsmall};
`;

export const CloseIcon = styled.button`
  ${buttonReset};
  margin-left: ${spacing.small};
  cursor: pointer;
`;
