import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const StoreListStyled = styled.div`
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-bottom: 0;
  }

  .Accordion > div {
    border-top: thin solid ${colors.border};
  }

  .CollapseHeader {
    height: ${spacing.large};
    cursor: pointer;
  }

  a {
    text-decoration: none;
    font-size: 14px;
    color: ${colors.black};

    &:hover {
      color: ${colors.gray1};
    }
  }

  p,
  label {
    font-size: 14px;
  }

  .CollapseContent {
    padding: 0;

    p {
      margin-bottom: ${spacing.small};
    }
  }

  .Label {
    margin: 0;
  }

  .Address {
    margin: 0 0 30px;
    line-height: 1.25rem;
  }

  .ViewOnMap {
    margin-bottom: 30px;
  }

  .Contact {
    display: flex;
    flex-direction: row;
    border-top: thin solid ${colors.gray4};
    align-items: center;
    padding: 0;
    height: 60px;

    &.EmailAndPhoneWrapper {
      flex-direction: column;
      height: auto;
      padding: 20px 0;
      align-items: flex-start;

      @media screen and (min-width: ${breakpoints.mediumMobile}) {
        flex-direction: row;
        height: 60px;
        padding: 0;
        align-items: center;
      }

      @media screen and (max-width: ${breakpoints.bigMobile}) {
        justify-content: center;
      }
    }

    div {
      display: inline-flex;

      &.Right {
        padding-left: 20px;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding-left: ${spacing.small};
          height: 100%;
          align-items: center;
        }
      }

      &.AddBorderRight {
        border-right: thin solid ${colors.gray4};
      }

      &.Middle {
        padding: 0 20px;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding: 0 ${spacing.small};
          border-right: thin solid ${colors.gray4};
          border-left: thin solid ${colors.gray4};
          height: 100%;
          align-items: center;
        }
      }

      &.Left {
        padding-right: 20px;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding-right: ${spacing.small};
          height: 100%;
          align-items: center;
        }
      }
    }

    &.VisitSite {
      justify-content: flex-end;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    label {
      width: 100%;
      margin-bottom: 6px;
    }

    &.StoreSells {
      align-items: center;
      min-height: 90px;
      height: auto;

      .StoresWrapper {
        flex-direction: column;
      }

      a {
        text-decoration: underline;
      }
    }

    .Stores {
      width: 100%;

      div {
        .Separator {
          display: inline-flex;
          margin: 0 5px;
        }
      }
    }
  }

  .Accordion > div:last-of-type {
    border-bottom: thin solid ${colors.border};
  }

  .MapLink {
    text-decoration: none;
  }

  .LeftIconLink {
    position: relative;

    svg {
      margin-right: 6px;
    }
  }
`;
