import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { ArticlePlug } from '../ArticlePlug/ArticlePlug';
import { Markdown } from '../Markdown/Markdown';

export const ExploreAreaBlockStyledNew = styled.div`
  padding: 3.5rem 20px 3rem;
  background: ${colors.gray4};

  > * {
    max-width: 1200px;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 9.5rem 30px 6rem;
  }
`;

export const ArticleItem = styled.article`
  & + & {
    margin-top: 1.5rem;
    flex: 1;
  }
`;

export const ExploreAreaColumnsStyled = styled.div`
  display: grid;
  width: 100%;

  > * {
    height: auto;
  }

  > * + * {
    margin-top: 1.5rem;
  }

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-flow: row;
    grid-column-gap: ${spacing.pagePaddingMobile};

    .col1 {
      margin-top: 96px;
    }

    > * + * {
      margin-top: 0;
    }
  }
`;

export const ExploreAreaMobileView = styled.div`
  display: flex;
  flex-direction: column;

  .ArticleItem {
    flex: 1;
    padding-bottom: ${spacing.medium};
  }
`;

export const ExploreAreaHorizontalStyled = styled.div`
  .HorizontalWrapper {
    display: flex;
    flex-direction: column;
    max-width: ${breakpoints.maxPageWidth};

    .ArticleItem {
      flex: 1;
      padding-bottom: ${spacing.medium};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    .HorizontalWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${spacing.pagePaddingMobile};
    }
  }
`;

export const ArticlePlugCustomStyle = styled(ArticlePlug)`
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${spacing.regular};
  }
`;

export const ExploreContentWrapper = styled.div`
  margin: ${spacing.medium} auto;
  margin-bottom: 0;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: ${spacing.xlarge};
  }
`;

export const ExploreHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  h2 {
    margin-bottom: ${spacing.regular};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${spacing.medium};
  }
`;

export const ExploreDescription = styled(Markdown)`
  font-size: ${fontSizes.h4};
  line-height: ${lineHeights.h4};
  margin-bottom: 0;
  margin-right: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    max-width: 57%;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    max-width: 40%;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spacing.large} 0;
`;
