import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { Paragraph } from '../Paragraph/Paragraph';

const arrowAnimationTime = '0.1s';

export type ArrowLinkDirection = 'forward' | 'back' | 'down';

interface DirectionProps {
  $direction?: ArrowLinkDirection;
}

interface ArrowLinkStyledProps extends DirectionProps {
  $white?: boolean;
}

export const ArrowLinkStyled = styled.div<ArrowLinkStyledProps>`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xsmall};
  gap: ${spacing.xsmall};

  svg {
    width: ${spacing.regular};
    height: ${spacing.regular};
    stroke: ${({ $white }) => ($white ? colors.white : colors.black)};

    path {
      stroke: ${({ $white }) => ($white ? colors.white : colors.black)};
      transition: all ${arrowAnimationTime} linear;
    }

    path:nth-child(1) {
      d: path('M23.5 23H1V0.6');

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        d: path('M20 23H1V4');
      }
    }

    path:nth-child(2) {
      transform-origin: center;

      ${({ $direction }) =>
        $direction === 'forward'
        && css`
          transform: rotate(-90deg);
        `}
      ${({ $direction }) =>
        $direction === 'back'
        && css`
          transform: rotate(90deg);
        `}
    }

    path:nth-child(3) {
      d: path('M1 1h22v22');

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        d: path('M4 1h19v19');
      }
    }
  }

  &:hover {
    svg > g > path:nth-child(1) {
      d: path('M23.5 23H1V0.6');
    }

    svg > g > path:nth-child(3) {
      d: path('M1 1h22v22');
    }
  }
`;

const innerStyle = css<DirectionProps>`
  display: inline-flex;
  align-items: center;
  flex-direction: ${({ $direction }) =>
    $direction !== 'back' ? 'row' : 'row-reverse'};
  margin: 0;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  color: inherit;
  gap: ${spacing.xsmall};

  &:hover {
    color: ${colors.gray1};
  }
`;

export const ArrowLinkLink = styled(Link)<DirectionProps>`
  ${innerStyle};
`;
export const ArrowLinkText = styled(Paragraph)<DirectionProps>`
  ${innerStyle};
`;
