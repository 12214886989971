import styled from 'styled-components';

import { fontSizes } from '@hultafors/hultafors/tokens';

export const LabelStyled = styled.label`
  width: 100%;
  clear: both;
  float: left;
  font-size: ${fontSizes.xsmall};
`;
