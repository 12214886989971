import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const FactBoxStyled = styled.div`
  width: 100%;
  padding: ${spacing.pagePaddingMobile};

  .FactsImage {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      margin-bottom: 0;
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .TextContent {
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      margin-top: 20%;
      padding-left: calc(25% + ${spacing.small});

      &::before {
        content: '';
        position: absolute;
        display: flex;
        left: 0;
        top: ${spacing.small};
        height: 1px;
        width: 20%;
        background-color: ${colors.red};
      }
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-left: calc(20% + ${spacing.xxsmall});

      &::before {
        width: calc(20% - ${spacing.small});
      }
    }

    @media screen and (min-width: ${breakpoints.desktopMedium}) {
      margin-top: calc(20% - ${spacing.xsmall});
      padding-left: calc(25% + ${spacing.xxsmall});

      &::before {
        width: calc(25% - ${spacing.small});
      }
    }

    h2 {
      display: flex;
      align-items: center;
      margin-bottom: ${spacing.small};

      &::before {
        content: '';
        position: relative;
        display: inline-flex;
        height: 1px;
        width: 28px;
        background-color: ${colors.red};
        margin-right: ${spacing.small};
      }

      @media screen and (min-width: ${breakpoints.largeMobile}) {
        &::before {
          display: none;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      font-size: ${fontSizes.small};
      line-height: 1.375rem;
    }

    a {
      color: ${colors.red};
      text-decoration: none;

      &:hover {
        color: ${colors.redHover};
      }
    }
  }
`;
