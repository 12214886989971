import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

import { ArrowLink } from '../ArrowLink/ArrowLink';

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${spacing.medium};

  .TitleHeader {
    margin-top: ${spacing.regular};
    margin-bottom: ${spacing.small};
  }
`;

export const ReadMoreLink = styled(ArrowLink)`
  a,
  p {
    font-size: ${fontSizes.xsmall};
    line-height: ${fontSizes.xsmall};
    color: ${colors.black};
  }
`;

export const IconImage = styled.div`
  position: relative;
  aspect-ratio: 1/1;

  img {
    transition: transform 0.3s ease-in-out !important;
  }

  img:hover {
    transform: scale(1.01);
  }
`;
