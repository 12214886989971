import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

interface LinkButtonStyledProps {
  $fullWidth?: boolean;
  $iconUrl?: string;
}
export const LinkButtonStyled = styled.div<LinkButtonStyledProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  display: inline-flex;
  height: ${spacing.medium};
  padding: 0 ${spacing.xsmall};
  box-sizing: border-box;
  background-color: ${colors.white};
  border: thin solid ${colors.black};
  cursor: pointer;
  transition: 0.2s;
  text-transform: uppercase;

  &:hover {
    background-color: ${colors.black};
    color: ${colors.white};
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: ${fontSizes.xsmall};
    font-weight: ${fontWeights.medium};
    color: ${colors.black};
    letter-spacing: 0.214rem;
    text-decoration: none;
    text-align: center;
  }

  &.Black {
    border: thin solid ${colors.black};
    background-color: ${colors.black};

    a {
      color: ${colors.white};
    }

    &:hover {
      border: thin solid ${colors.gray1};
      background-color: ${colors.gray1};
    }
  }

  &.Icon {
    a::after {
      content: '';
      display: inline-flex;
      width: ${spacing.regular};
      height: ${spacing.regular};
      background-image: ${({ $iconUrl }) => `url(${$iconUrl})`};
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: ${spacing.xsmall};
      transition: transform 0.1s linear;
    }
  }
`;
