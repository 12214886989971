import styled from 'styled-components';

import { Button } from '../Button/Button';

export const ToggleButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    /* width: 16px; */
    height: 16px;
    margin-left: 16px;

    path {
      color: #000;
    }
  }

  &:hover {
    svg path {
      color: #fff;
    }
  }
`;
