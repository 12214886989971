import dynamic from 'next/dynamic';

import { HultaforsProductDetails } from '@hultafors/hultafors/types';

import { ImageAttribute } from '../ImageAttribute/ImageAttribute';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItem),
);

interface DocumentsAccordionProps {
  product?: HultaforsProductDetails;
  label?: string;
}

export const DocumentsAccordion: React.FC<DocumentsAccordionProps> = ({
  product,
  label,
}) => {
  if (!product?.documents?.length) {
    return null;
  }
  const documents
    = product.documents
    && product.documents.length
    && product.documents.map((item, index) => {
      return (
        <ImageAttribute
          key={`Doc-${index}`}
          title={item.name}
          attributes={item.attributes ?? []}
          attributeName="documents"
          url={item.url ?? ''}
          size={item.size}
        />
      );
    });
  if (!documents || !documents.length) {
    return null;
  }
  return (
    <Accordion allowZeroExpanded>
      <AccordionItem label={label || ''}>{documents}</AccordionItem>
    </Accordion>
  );
};
