import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

interface DropdownStyledProps {
  $dark?: boolean;
  $disabled?: boolean;
}

interface IsOpenProps {
  $isOpen?: boolean;
}

type DropdownLabelProps = DropdownStyledProps & IsOpenProps;

export const DropdownStyled = styled.div<DropdownStyledProps>`
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 26px;
  pointer-events: none;

  ${({ $disabled }) =>
    $disabled
    && css`
      opacity: 0.3;
    `}
`;

export const DropdownLabel = styled.button<DropdownLabelProps>`
  appearance: none;
  border: 0;
  background: transparent;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: ${({ $dark }) => ($dark ? colors.white : colors.black)};
  transition: color 0.2s linear;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};

  &:hover {
    color: ${({ $dark }) => ($dark ? colors.grayBtnHover : colors.gray1)};
  }

  padding-block: 0;
  padding-inline-end: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    height: 12px;
    width: 12px;
    background-image: ${({ $dark }) =>
      $dark
        ? "url('/assets/gfx/chevron_white.svg')"
        : "url('/assets/gfx/chevron.svg')"};
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    right: 0;
    top: 13px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: transform 0.1s linear;
  }

  ${({ $isOpen }) =>
    $isOpen
    && css`
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    `}
`;

export const DropdownList = styled.ul<IsOpenProps>`
  position: absolute;
  display: none;
  flex-direction: column;
  gap: ${spacing.small};
  background: ${colors.white};
  width: auto;
  min-width: 120px;
  right: 0;
  top: 95%;
  padding: 16px 12px;
  box-shadow: 0 2px 10px 0 ${colors.boxshadowTransparent};
  transition: opacity 0.1s cubic-bezier(0, 0, 0.38, 0.9),
    max-height 0.5s cubic-bezier(0, 0, 0.38, 0.9);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  z-index: 100;
  margin: 0;
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'all' : 'none')};

  ${({ $isOpen }) =>
    $isOpen
    && css`
      display: flex;
      opacity: 1;
      overflow: auto;
      max-height: 15rem;
    `}
`;

export const DropdownListItem = styled.li<{ $focus?: boolean }>`
  text-align: left;
  color: ${colors.black};
  font-size: 14px;
  transition: color 0.1s linear;
  cursor: pointer;
  padding: ${spacing.xxsmall} ${spacing.small};
  display: flex;
  height: 100%;
  align-items: center;

  &:hover {
    color: ${colors.gray1};
  }
`;
