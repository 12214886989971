import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

interface FormBlockStyledProps {
  $image?: boolean;
  $height?: number;
}

export const FormBlockStyled = styled.div<FormBlockStyledProps>`
  .ContentWrapper {
    display: flex;
    flex-direction: column;
    column-gap: 10%;
    padding: 5rem;

    .TextContent {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: flex-start;
      width: 100%;

      button {
        margin-bottom: ${spacing.medium};
        margin-top: ${spacing.small};
      }
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      flex-direction: row-reverse;
      justify-content: ${({ $image }) =>
        $image ? 'space-around' : 'flex-start'};

      .TextContent {
        width: ${({ $image }) => ($image ? '50%' : '100%')};
      }
    }
  }

  .ImageContainer {
    max-width: unset;
    overflow: hidden;
    height: 400px;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      max-width: 100%;
      width: 50%;
    }
  }
`;

export const InlineForm = styled.div<FormBlockStyledProps>`
  overflow: auto;
  height: ${({ $height }) => ($height ? `${$height}vh` : '150vh')};
  width: 100%;
  display: flex;
  justify-content: center;

  iframe {
    max-width: 850px !important;
    border: none;
    height: 100%;
  }
`;

export const StyledToast = styled.div`
  position: fixed;
  max-width: 400px;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding: ${spacing.medium};
  background: ${colors.white};
  box-shadow: rgb(0 0 0 / 56%) 0 22px 70px 4px;

  .TextContent {
    button {
      margin-top: ${spacing.small};
    }
  }

  .CloseBtn {
    appearance: none;
    border: 0;
    top: 0;
    right: 0;
    padding: ${spacing.small};
    background: transparent;
    display: flex;
    position: absolute;
    cursor: pointer;

    svg path {
      transition: 0.2s ease;
    }

    &:hover {
      svg path {
        fill: ${colors.gray1};
      }
    }
  }
`;

export const HiddenFormBlock = styled.span`
  height: 100px;
  display: block;
  position: absolute;
`;
