import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const AmountInputStyled = styled.label`
  display: inline-flex;
  position: relative;
  max-width: 4rem;
  box-sizing: border-box;
`;

export const Label = styled.span``;

const sharedButtonStyle = css`
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  display: block;
  background: transparent;
  position: absolute;
  height: ${spacing.small};
  right: 5px;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    right: 8px;
  }
`;

export const ButtonUp = styled.button`
  ${sharedButtonStyle};
  top: 7px;

  svg {
    transform: rotate(180deg);
  }
`;

export const ButtonDown = styled.button`
  ${sharedButtonStyle};
  bottom: 7px;
`;

export const AmountInputInput = styled.input`
  height: ${spacing.medium};
  border: thin solid ${colors.border};
  outline: none;
  border-radius: 0;
  padding: ${spacing.small} ${spacing.xsmall};
  width: 100%;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.normal};

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    max-width: ${spacing.large};
    padding: ${spacing.small};
    padding-right: ${spacing.xsmall};
  }
`;
