import { useState } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';

import { useLockBodyScroll } from '@hultafors/shared/hooks';

import { HeroBlockFragment } from '@hultafors/hultafors/types';

import { Button } from '../Button/Button';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Icon } from '../Icon/Icon';
import { Markdown } from '../Markdown/Markdown';

import {
  HeroBlockStyled,
  HeroButtonContainer,
  HeroButtonWrapper,
  HeroContent,
  HeroScrollText,
  IconContainer,
  VideoStyled,
} from './HeroBlock.styled';
const AspectRatioImage = dynamic(() =>
  import('@hultafors/shared/components').then(
    (module) => module.AspectRatioImage,
  ),
);

const Dialog = dynamic(() =>
  import('../Dialog/Dialog').then((mod) => mod.Dialog),
);

const HeroDescription: React.FC<{ children?: string }> = ({ children }) => (
  <Markdown variant="light" options={{ forceBlock: true }}>
    {children}
  </Markdown>
);

interface HeroBlockProps extends HeroBlockFragment {
  inSlider?: boolean;
  className?: string;
  priority?: boolean;
  secondCtaButtonText?: string;
  secondCtaUrl?: string;
}

export const HeroBlock: React.FC<HeroBlockProps> = ({
  className,
  ctaButtonText,
  darkText,
  description,
  formButtonText,
  formPopupUrl,
  mobileImage,
  desktopImage,
  tabletImage,
  priority,
  video,
  label,
  title,
  verticalText,
  ctaUrl,
  small,
  inSlider,
  icon,
  displayIcon,
  secondCtaButtonText,
  secondCtaUrl,
}) => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  useLockBodyScroll(formModalOpen);

  const toggleModal = () => {
    setFormModalOpen(!formModalOpen);
  };

  const image = mobileImage || tabletImage || desktopImage;

  if (!image && !video) {
    return null;
  }

  return (
    <HeroBlockStyled
      $image={image}
      className={className}
      $darkText={darkText}
      $small={small}
      $video={!!video}
    >
      <Head>
        {desktopImage?.responsiveImage?.src && (
          <link
            rel="preload"
            as="image"
            href={desktopImage.responsiveImage.src}
            imageSrcSet={desktopImage.responsiveImage.webpSrcSet || ''}
            imageSizes={desktopImage.responsiveImage.sizes || ''}
          />
        )}
        {tabletImage?.responsiveImage?.src && (
          <link
            rel="preload"
            as="image"
            href={tabletImage.responsiveImage.src}
            imageSrcSet={tabletImage.responsiveImage.webpSrcSet || ''}
            imageSizes={tabletImage.responsiveImage.sizes || ''}
          />
        )}
        {mobileImage?.responsiveImage?.src && (
          <link
            rel="preload"
            as="image"
            href={mobileImage.responsiveImage.src}
            imageSrcSet={mobileImage.responsiveImage.webpSrcSet || ''}
            imageSizes={mobileImage.responsiveImage.sizes || ''}
          />
        )}
      </Head>
      {!video
        ? (
          <AspectRatioImage
            desktopImage={desktopImage}
            tabletImage={tabletImage}
            mobileImage={mobileImage}
            priority={priority}
          />
          )
        : (
          <VideoStyled>
            <div className="VideoContainer">
              <video
                autoPlay
                loop
                muted={true}
                poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              >
                <source src={video?.url} type="video/mp4" />
              </video>
            </div>
          </VideoStyled>
          )}
      <HeroContent>
        <div className={`LeftContent ${label}`}>
          <div className="Inner">
            {label && <span className="HeroLabel">{label}</span>}
            {!inSlider && title && <h1 aria-label={title} />}
            {title && <h2>{title}</h2>}
            {description && (
              <div className="HeroDescription">
                <HeroDescription>{description}</HeroDescription>
              </div>
            )}
            <HeroButtonContainer>
              {ctaButtonText && ctaUrl && (
                <HeroButtonWrapper $dark={!image || !video || darkText}>
                  <ButtonLink to={ctaUrl} inverted center>
                    {ctaButtonText}
                  </ButtonLink>
                </HeroButtonWrapper>
              )}
              {secondCtaButtonText && secondCtaUrl && (
                <HeroButtonWrapper $dark={!image || !video || darkText}>
                  <ButtonLink to={secondCtaUrl} inverted center>
                    {secondCtaButtonText}
                  </ButtonLink>
                </HeroButtonWrapper>
              )}
              {formButtonText && (
                <HeroButtonWrapper $dark={!image || !video || darkText}>
                  <Button className="Inverted" onClick={toggleModal}>
                    {formButtonText}
                  </Button>
                </HeroButtonWrapper>
              )}
            </HeroButtonContainer>

            {formModalOpen && (
              <Dialog
                onHandleClose={toggleModal}
                formUrl={formPopupUrl || ''}
              />
            )}
          </div>
          {verticalText && (
            <HeroScrollText
              $image={!!image}
              $video={!!video}
              $darkText={!!darkText}
            >
              <Icon svg="scroll" />
              <span>{verticalText}</span>
            </HeroScrollText>
          )}
        </div>
        {icon?.url && displayIcon && (
          <IconContainer>
            <Image src={icon.url} fill alt={icon.alt || 'icon'} />
          </IconContainer>
        )}
      </HeroContent>
    </HeroBlockStyled>
  );
};
