import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';

export const ProductDetailsImagesStyled = styled.div``;

export const DesktopGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr;
  }

  gap: 24px;
`;

export const ImageWrapper = styled.button`
  appearance: none;
  border-radius: 0;
  padding: 0;
  background: transparent;
  position: relative;
  border: thin solid ${colors.gray4};
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  cursor: pointer;
`;

export const DesktopImages = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    display: block;
    margin-top: var(--header-height);
    padding-top: ${spacing.regular};
  }
`;

export const ShowAllButton = styled(Button)`
  margin-top: ${spacing.regular};
`;

export const MobileImages = styled.div`
  display: block;
  height: 375px;
  margin: var(--header-height) -${spacing.pagePaddingMobile} ${spacing.small} -${spacing.pagePaddingMobile};
  border-bottom: thin solid ${colors.border};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    display: none;
  }
`;

export const ZoomIcon = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 0.5rem;
  top: 0.5rem;
  color: ${colors.gray3};

  &:hover {
    color: ${colors.gray2};
  }
`;
