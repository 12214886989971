import {
  ApiSettings,
} from '@hultafors/shared/types';

import { createMeta } from '@hultafors/hultafors/helpers/create-meta/create-meta';
import {
  MetaData,
  PageNotFoundFragment,
  SeoFragment,
} from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { HeroBlock } from '../HeroBlock/HeroBlock';
import { Page } from '../Page/Page';
import { Products } from '../Products/Products';
import { Section } from '../Section/Section';

import { StyledNotFoundPageContent } from './NotFoundPageContent.styled';

interface NotFoundPageContentProps {
  content: PageNotFoundFragment;
  settings: ApiSettings;
}
export const NotFoundPageContent: React.FC<NotFoundPageContentProps> = ({
  // settings: defaultSettings,
  // content: defaultContent,
  settings,
  content,
}) => {
  // const [settings, setSettings] = useState<ApiSettings>(defaultSettings);
  // const [content, setContent] = useState<PageNotFoundFragment>(defaultContent);
  // const { globalContent, setGlobal, setAllMenuItems, setFooter } = useGlobal();
  // const { data: pageData, error: pageError } = useSWR<PageNotFoundResponse>(
  //   '/api/pageNotFound',
  //   {
  //     revalidateOnMount: true,
  //   },
  // );
  // const {
  //   data: productsData,
  //   error: productsError,
  //   isValidating: productsLoading,
  // } = useSWR<ParsedProductsApiResponse<HultaforsProduct>>(
  //   defaultContent?.products
  //   && `/api/productslist/${23}?productCatalogNodeId=${
  //       defaultContent.products
  //     }&pageSize=${NOTFOUND_PAGE_SIZE}`,
  //   {
  //     revalidateOnMount: true,
  //   },
  // );

  // useEffect(() => {
  //   setSettings(getApiSettingsFromHost(window.location.hostname));
  // }, []);

  // useEffect(() => {
  //   if (pageData?.content) {
  //     setContent(pageData.content as PageNotFoundRecord);
  //   }
  //   if (pageData?.global) {
  //     setGlobal?.(pageData.global);
  //   }
  //   if (pageData?.footer) {
  //     setFooter?.(pageData.footer as GlobalFields['footer']);
  //   }
  //   if (pageData?.allMenuItems) {
  //     setAllMenuItems?.(pageData.allMenuItems as GlobalFields['allMenuItems']);
  //   }
  // }, [pageData]);

  // useEffect(() => {
  //   if (pageError) {
  //     setContent(defaultContent);
  //   }
  // }, [pageError]);
  return (
    <Page
      metadata={
        createMeta(
          content?.seo as SeoFragment,
          settings?.language?.lang,
        ) as MetaData
      }
    >
      <StyledNotFoundPageContent>
        <HeroBlock
          darkText={true}
          description={content?.heroDescription || ''}
          title={content?.heroTitle || 'Page Not Found'}
          className="NoBorderBottom"
        />
        <Section noMarginTop>
          <Grid
            columnGap={[{ columnGap: 20 }]}
            columns={[
              { columns: 1 },
              { breakpoint: 'largeMobile', columns: 2 },
              { breakpoint: 'desktop', columns: 3 },
            ]}
          >
            <Products
              products={[]}
              badgeText=""
              modelText=""
              numberOfItems={0}
              loading={false}
              // products={productsData?.items || []}
              // badgeText={globalContent?.newLabel || ''}
              // modelText={globalContent?.modelsLabel || ''}
              // numberOfItems={NOTFOUND_PAGE_SIZE}
              // loading={productsLoading}
            />
          </Grid>
        </Section>
      </StyledNotFoundPageContent>
    </Page>
  );
};
