import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const ImageAttributeStyled = styled.div`
  .ImgWrapper {
    display: flex;
    margin-right: ${spacing.xsmall};

    img {
      max-width: 24px;
      max-height: 24px;
    }
  }

  h4.Title {
    display: flex;
    align-items: center;
    line-height: 22px;
    margin-top: ${spacing.small};

    img {
      max-width: 24px;
      max-height: 24px;
      margin-right: ${spacing.small};
    }
  }

  .Attribute {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;

    &:first-of-type {
      padding-top: 0;
    }

    p {
      white-space: pre-wrap;
      margin-top: 0;
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .DocumentWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: ${spacing.small};

    .ImgWrapper {
      margin-top: 4px;
    }

    p {
      white-space: pre-wrap;
      margin-top: 0;
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .Attribute a:hover {
    color: ${colors.gray1};
  }

  .DocumentWrapper a:hover {
    color: ${colors.gray1};
  }
`;
