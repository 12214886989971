import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const DoubleImageStyled = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;

    img {
      width: 50%;
    }
  }
`;
