import styled, { css } from 'styled-components';

import { colors } from '@hultafors/hultafors/tokens';

export const NavItemStyled = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  background: transparent;
  position: relative;
  cursor: pointer;
`;

const sharedIconStyle = css`
  position: relative;
  color: ${colors.black};

  &:hover {
    opacity: 0.7;
  }
`;

export const FavoriteIcon = styled.div`
  ${sharedIconStyle};
  .Badge {
    top: -7px;
    right: -9px;
  }
`;

export const LanguageIcon = styled.div`
  ${sharedIconStyle};
  .Badge {
    top: -7px;
    right: -9px;
  }
`;

export const CartIcon = styled.div`
  ${sharedIconStyle};
`;
export const SearchIcon = styled.div`
  ${sharedIconStyle};
`;
