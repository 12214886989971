import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

interface CollapseStyledProps {
  $noPadding?: boolean;
  $small?: boolean;
  $height?: number;
}

export const CollapseStyled = styled.div<CollapseStyledProps>`
  height: auto;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 0;
    padding: 0;
  }

  .CollapseHeader {
    position: relative;
    display: flex;
    align-items: center;
    height: ${spacing.large};
    margin: 0;

    .InnerCollapse {
      display: flex;
      flex-direction: column;

      h5 {
        width: 100%;
      }

      .SubLabel {
        font-size: ${fontSizes.xsmall};
        color: ${colors.gray1};
        width: 100%;
        display: inline-block;
        margin-top: ${spacing.xxsmall};
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: inline-flex;
      right: 0;
      top: 50%;
      width: 12px;
      height: 12px;
      background: url('/assets/gfx/chevron.svg') no-repeat center center;
      transform: translateY(-50%);
      transition: transform 0.3s;
    }

    &.isOpen::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .CollapseContent {
    height: auto;
    max-height: 0;
    transition: max-height 0.4s;
    overflow-y: hidden;
    padding-left: ${({ $noPadding }) => ($noPadding ? '0' : spacing.regular)};

    &.isOpen {
      max-height: ${({ $height }) => $height}px;
    }

    span {
      display: flex;
      width: 100%;

      a {
        margin: ${spacing.xsmall} 0;
        font-size: ${fontSizes.small};
      }
    }
  }
`;
