import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const FooterStyled = styled.div`
  margin-top: auto;
  width: 100%;
  background-color: ${colors.black};
  color: ${colors.white};
  font-size: ${fontSizes.small};

  .Grid {
    position: relative;
  }

  section {
    padding: 32px ${spacing.pagePaddingMobile} ${spacing.small};
    margin: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding: ${spacing.medium} ${spacing.pagePadding} 10px;
    }
  }

  a,
  .cookieSettings {
    cursor: pointer;
    text-decoration: none;

    &.Underline {
      text-decoration: underline;
    }
  }

  span,
  a,
  p,
  span a,
  p a,
  .cookieSettings {
    color: ${colors.white};
    font-size: ${fontSizes.small};
    letter-spacing: 0;
  }

  a:hover {
    color: ${colors.gray1};
  }

  .FooterHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.desktop}) {
      flex-direction: row;
      margin-bottom: ${spacing.block};
      align-items: center;
    }

    p {
      margin-bottom: 0;
      margin-left: ${spacing.regular};
    }

    .LogoWrapper {
      display: flex;

      @media screen and (min-width: ${breakpoints.desktop}) {
        display: inline-flex;
      }
    }

    .Dropdown {
      display: flex;
      align-items: center;

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin-right: ${spacing.regular};
      }
    }

    .FooterFunctions {
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      @media screen and (min-width: ${breakpoints.desktop}) {
        display: inline-flex;
        flex-direction: row;
      }

      .Dropdown {
        margin-left: -10px;
      }

      .PartnerLoginLink {
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;

        svg {
          margin-left: ${spacing.xsmall};
        }
      }
    }
  }

  .cookieSettings {
    appearance: none;
    border: 0;
    background: transparent;
    cursor: pointer;
    font-weight: 100;
  }

  .Underline {
    a {
      text-decoration: underline;
    }
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex-basis: 65%;
  text-align: left;

  .IntroText {
    padding-bottom: ${spacing.regular};
  }

  p,
  a {
    margin: 0;
    max-width: 600px;
  }
`;

export const SubscribeAreaWrapper = styled.div`
  flex-basis: 25%;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
    align-items: center;
  }

  .SocialMedia {
    display: flex;
    padding-bottom: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-bottom: 0;
    }

    a {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
      }

      &:not(:last-of-type) {
        margin-right: ${spacing.regular};
      }
    }
  }
`;

export const Brands = styled.span`
  > * + * {
    &::before {
      content: ' | ';
    }
  }
`;
