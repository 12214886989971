import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { RELATED_PRODUCTS_PAGE_SIZE } from '@hultafors/hultafors/helpers/constants';
import { useGlobal } from '@hultafors/hultafors/hooks';
import {
  HultaforsProduct,
  RelatedProductsBlockFragment,
} from '@hultafors/hultafors/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H2 } from '../H2/H2';
import { Loader } from '../Loader/Loader';
import { Products } from '../Products/Products';
import { Section } from '../Section/Section';

import { RelatedProductsStyled } from './RelatedProducts.styled';

export const RelatedProductsBlock: React.FC<RelatedProductsBlockFragment> = ({
  ctaButtonText,
  ctaPageUrl,
  productCatalogNodeId,
  title,
}) => {
  const {
    globalContent,
    siteLocale,
  } = useGlobal();
  const productListUrl = `/api/productslist/23?${new URLSearchParams({
    productCatalogNodeId: `${productCatalogNodeId}`,
    pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
    includeFilters: 'false',
    siteId: siteLocale?.parttrapSiteId ?? '',
    market: siteLocale?.parttrapMarket ?? '',
    lang: siteLocale?.parttrapLanguage ?? '',
  }).toString()}`;

  const { data, isValidating } = useSWR<ParsedProductsApiResponse<HultaforsProduct>>(productListUrl, {
    revalidateOnMount: true,
  });

  // TODO Replace loader with product card skeletons
  return (
    <RelatedProductsStyled>
      <Section borderTop>
        {title && (
          <Grid>
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'desktop', columns: 12, start: 2 },
              ]}
            >
              <H2>{title}</H2>
            </GridChild>
          </Grid>
        )}
        {isValidating
          ? (
            <Loader />
            )
          : (
              data?.items && (
                <Grid
                  columnGap={[{ columnGap: 20 }]}
                  columns={[
                    { columns: 1 },
                    { breakpoint: 'mediumMobile', columns: 2 },
                    { breakpoint: 'desktop', columns: 4 },
                  ]}
                >
                  { globalContent?.newLabel && globalContent?.modelsLabel && (
                    <Products
                      products={data.items}
                      badgeText={globalContent?.newLabel}
                      modelText={globalContent?.modelsLabel}
                      loading={isValidating}
                      numberOfItems={RELATED_PRODUCTS_PAGE_SIZE}
                    />
                  ) }
                </Grid>
              )
            )}

        {ctaButtonText && ctaPageUrl && (
          <div className="LinkWrapper">
            <ButtonLink className="Black ButtonLink" to={ctaPageUrl}>
              {ctaButtonText}
            </ButtonLink>
          </div>
        )}
      </Section>
    </RelatedProductsStyled>
  );
};
