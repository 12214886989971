import styled from 'styled-components';

export const ImageStyled = styled.div`
  overflow: hidden;
  max-width: 100%;

  img {
    width: 100%;
    max-width: 100%;
  }
`;
